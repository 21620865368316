import React, { useState, useEffect } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link, redirect } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ReactComponent as Icon1 } from "../../assets/img/dashboard/icons/tokend-icon-1.svg";
import ReactSpeedometer from "react-d3-speedometer";
import busd from "../../assets/img/dashboard/icons/tticon.svg";
import evcLogo from "../../assets/img/dashboard/icons/evc-logo.png";
import evcLogoLg from "../../assets/img/dashboard/icons/evc-logo-lg.png";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import axios from "axios";

import {
  getRBEarning,
  getUniLevelEarning,
} from "../../ContractAction/EVCNFTContractAction";
import {
  getRemainingRbEvcValue,
  getClaimedRbEvcValue,
  getRbClaimPerc,
  setClaimRB,
} from "../../ContractAction/PoolContractAction";
import AdPopup from "../../Components/Single/adPopup";
import {
  APIGETADS,
  BaseExplorerUrl,
} from "../../ContractAction/ContractDependency";
import { useTranslation } from "react-i18next";
//dev: start RankBonus Page
const useStyles = makeStyles({
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100vh",
  },
});

const RankBonus = ({ title }) => {
  //dev: Get the address for local storage
  const [RBEarningBUSDValue, setRBEarningBUSDValue] = useState(0);
  const [uniLevelEarningValue, setUniLevelEarningValue] = useState(0);
  const [remainingEvcRbValue, setRemainingEvcRbValue] = useState(0);
  const [RBClaimedEVCValue, setRBClaimedEVCValue] = useState(0);
  const [RbClaimPerc, setRbClaimPerc] = useState(0);
  const [overallRewardUSDC, setOverallRewardUSDC] = useState(0);
  const [segmentColors, setSegmentColors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [adsData, setAdsData] = useState();
  const [showPopup, setShowPopup] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [adsStatus, setAdsStatus] = useState(
    JSON.parse(localStorage.getItem("adsStatus")) || false
  );
  const { t } = useTranslation();
  const classes = useStyles();

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  console.log("showPopup", showPopup);
  const setClaimrB = async () => {
    let txHash = await setClaimRB();
    handleTxhashShow(txHash);
  };

  const handleTxhashShow = async (e) => {
    toast.success(
      <div>
        Transaction Receipt: <br />
        <a
          href={`${BaseExplorerUrl}tx/${e}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          View on Block Explorer
        </a>
      </div>,
      {
        position: "top-right",
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  };

  const fetchSegmentColors = async () => {
    try {
      const percValue = await getRbClaimPerc();
      const totalSegments = 10; // Total number of segments
      const coloredSegments = Math.ceil((percValue / 100) * totalSegments);
      const newSegmentColors = Array(totalSegments).fill("#FFFFFF1F");
      for (let i = 0; i < coloredSegments; i++) {
        newSegmentColors[i] = "#306FFF";
      }
      setSegmentColors(newSegmentColors);
    } catch (error) {
      console.error("Error fetching segment colors:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Fetch segment colors when the component mounts
    fetchSegmentColors();
  }, []);

  useEffect(() => {
    const getData = async () => {
      const RBBUSDInfo = await getRBEarning();
      const uniLevelInfo = await getUniLevelEarning();
      setUniLevelEarningValue(uniLevelInfo);
      setOverallRewardUSDC(Number(RBBUSDInfo) + Number(uniLevelInfo));
      setRBEarningBUSDValue(Number(RBBUSDInfo));
    };
    getData();
  }, []);

  useEffect(() => {
    const getData = async () => {
      const RBBUSDInfo = await getRBEarning();
      setRBEarningBUSDValue(RBBUSDInfo);
      const uniLevelInfo = await getUniLevelEarning();
      console.log("i am bonous", uniLevelInfo);
      setUniLevelEarningValue(uniLevelInfo);
      setOverallRewardUSDC(Number(RBBUSDInfo) + Number(uniLevelInfo));
      const RBEVCInfo = await getClaimedRbEvcValue();
      setRBClaimedEVCValue(Number(RBEVCInfo).toFixed(4));
      const remainingRbInfo = await getRemainingRbEvcValue();
      setRemainingEvcRbValue(Number(remainingRbInfo).toFixed(4));
      const rbClaimPerc = await getRbClaimPerc();
      // setRbClaimPerc(Number(rbClaimPerc));

      if (remainingRbInfo == 0) {
        setRbClaimPerc(0);
      } else {
        setRbClaimPerc(Number(rbClaimPerc));
      }
    };
    getData();
  }, []);

  useEffect(() => {
    document.title = title ? title : "TT Avatars | Rank Bonus";
    document.querySelector(".page-title").innerText = "Rank Bonus";
  }, []);

  //21 ads
  const fetch21Ads = async () => {
    try {
      const walletAddress = localStorage.getItem("connectedAccount");
      const response = await axios.get(
        // "https://viewer.trendads.ai/api/tt/getCount/0xa20579A8625d1EFe7a668cd0EBc161388A9e637D"
        `https://viewer.trendads.ai/api/tt/getCount/${walletAddress}`
      );
      if (response?.data?.statusCode === 200) {
        setAdsData(response?.data);
        localStorage.setItem(
          "adsStatus",
          JSON.stringify(response?.data?.watchedAdsStatus)
        );
        localStorage.setItem("watchedAds", response?.data?.watchedAds);
        localStorage.setItem("remainedAds", response?.data?.remainedAds);
        console.log("adsData", response?.data);
      }
      if (response?.data?.errorCode === 404) {
        setAdsStatus(false);
        localStorage.setItem(
          "adsStatus",
          JSON.stringify(response?.data?.watchedAdsStatus)
        );
        localStorage.setItem("watchedAds", response?.data?.watchedAds);
        localStorage.setItem("remainedAds", response?.data?.remainedAds);
      }
    } catch (error) {
      console.log("Error fetching 21 ads:", error);
    }
  };
  console.log("adsStatus", adsStatus);

  useEffect(() => {
    if (adsStatus === false) {
      fetch21Ads();
    }
  }, []);
  const fetch21Adstrue = async () => {
    if (adsStatus === true) {
      try {
        const walletAddress = localStorage.getItem("connectedAccount");
        const response = await axios.get(
          // "https://viewer.trendads.ai/api/tt/getCount/0xa20579A8625d1EFe7a668cd0EBc161388A9e637D"
          `https://viewer.trendads.ai/api/tt/getCount/${walletAddress}`
        );
        if (response?.data?.statusCode === 200) {
          setAdsData(response?.data);
          localStorage.setItem(
            "adsStatus",
            JSON.stringify(response?.data?.watchedAdsStatus)
          );
          localStorage.setItem("watchedAds", response?.data?.watchedAds);
          localStorage.setItem("remainedAds", response?.data?.remainedAds);
          console.log("adsData", response?.data);
        }
        if (response?.data?.errorCode === 404) {
          setAdsStatus(false);
          localStorage.setItem(
            "adsStatus",
            JSON.stringify(response?.data?.watchedAdsStatus)
          );
          localStorage.setItem("watchedAds", response?.data?.watchedAds);
          localStorage.setItem("remainedAds", response?.data?.remainedAds);
        }
      } catch (error) {
        console.log("Error fetching 21 ads:", error);
      }
    }
  };

  return (
    <div style={{ background: "#201f24", height: "100vh" }}>
      {console.log("catfsd", adsData)}
      <div className="dashboard-wrap">
        <div className="dash-content-area mt-1">
          {/* <div className="h1 font-gilroy fw-semibold mt-3 mb-4 pb-3">Affiliate Rank Bonus</div> */}
          <div className="container ">
            {/* <div className="row g-2">
              <div className="col-md-6 col-xl-4">
                <div className="dash-global-wrap">
                  <p className="mb-4 mt-0">
                    Total Rewards (Unilevel + RB) Earned (USDC)
                  </p>
                  <div className="d-flex flex-row justify-content-between mb-1">
                    <div className="d-flex align-items-center gap-2 col-3 mb-5">
                      <img src={busd} alt="" className="img-fluid" />
                      <span className="text-md opacity-75">USDC</span>
                    </div>
                    <div className="fs-4 font-gilroy font-semibold">
                      {overallRewardUSDC}
                    </div>
                  </div>
                </div>
                <div className="dash-global-wrap  align-item-center justify-content-center mt-3">
                  <p className="mb-4 mt-0">
                    Total Rank Bonus Commission Earned (TRND)
                  </p>
                  <div className="d-flex flex-row justify-content-between mb-1">
                    <div className="d-flex align-items-center gap-1 col-3 mb-5">
                      <Icon1 height={25} />
                      <span className="text-md opacity-75">TRND</span>
                    </div>
                    <div className="fs-4 font-gilroy font-semibold">
                      {remainingEvcRbValue}
                    </div>
                  </div>
                  <button
                    className="btn btn-primary-bg-custom btn-sm fs-6"
                    onClick={() => {
                      setClaimrB();
                      fetch21Adstrue();
                    }}
                    disabled={
                      remainingEvcRbValue > 0 ? false : true && !adsStatus
                    }
                    style={{
                      background: "transparent",
                      border: "1px solid",
                      width: "18vw",
                      borderRadius: "8px",
                      backgroundColor: "rgba(29, 42, 42, 1)",
                      borderColor: "#25a98e",
                      borderImageSlice: 1,
                      marginTop: "-30px",
                      width: "100%",
                      color: "#25a98e",
                    }}
                  >
                    Claim
                  </button>
                </div>
              </div>
              <div className="col-md-8 col-xl-8 mt-5">
                <div className="d-flex flex-column align-items-center">
                  {loading ? (
                    <div className="loader">Loading...</div>
                  ) : (
                    <ReactSpeedometer
                      height={200}
                      width={270}
                      needleHeightRatio={0.7}
                      endColor={"red"}
                      maxValue={100}
                      needleColor="#fff"
                      customSegmentStops={[
                        0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100,
                      ]}
                      segmentColors={[
                        "#A7F3D0",
                        "#A7F3D0",
                        "#A7F3D0",
                        "#34D399",
                        "#34D399",
                        "#34D399",
                        "#34D399",
                        "#059669",
                        "#059669",
                        "#059669",
                      ]}
                      value={RbClaimPerc}
                      currentValueText={`${RbClaimPerc}%`}
                      textColor={"white"}
                    />
                  )}

                  <button
                    className="btn btn-primary-bg-custom btn-sm fs-6"
                    onClick={() => {
                      setClaimrB();
                      fetch21Ads();
                    }}
                    disabled={
                      remainingEvcRbValue > 0 ? false : true && !adsStatus
                    }
                    style={{
                      background: "transparent",
                      border: "1px solid",
                      width: "20vw",
                      borderRadius: "8px",
                      backgroundColor: "rgba(29, 42, 42, 1)",
                      borderColor: "#25a98e",
                      borderImageSlice: 1,
                      color: "#25a98e",
                    }}
                  >
                    Claim
                  </button>
                  <div className="d-flex flex-row justify-content-between m-3">
                    <button
                      className="btn btn-primary-bg-custom btn-sm fs-6 me-3"
                      style={{
                        background: "transparent",
                        border: "1px solid",
                        width: "10vw",
                        borderRadius: "8px",
                        backgroundColor: "rgba(29, 42, 42, 1)",
                        borderColor: "#25a98e",
                        borderImageSlice: 1,
                        color: "#25a98e",
                      }}
                    >
                      {localStorage.getItem("watchedAds")} Ads Watched{" "}
                    </button>
                    <button
                      className="btn btn-primary-bg-custom btn-sm fs-6 ml-3"
                      style={{
                        background: "transparent",
                        border: "1px solid",
                        width: "9vw",
                        borderRadius: "8px",
                        backgroundColor: "rgba(29, 42, 42, 1)",
                        borderColor: "#25a98e",
                        borderImageSlice: 1,
                        color: "#25a98e",
                      }}
                    >
                      {localStorage.getItem("remainedAds")} Ads Remaining
                    </button>
                  </div>
                  <button
                    className="btn btn-primary-bg-custom btn-sm fs-6 ml-3"
                    style={{
                      background: "transparent",
                      border: "1px solid",
                      width: "9vw",
                      borderRadius: "8px",
                      backgroundColor: "rgba(29, 42, 42, 1)",
                      borderColor: "#25a98e",
                      borderImageSlice: 1,
                      color: "#25a98e",
                    }}
                    disabled={adsStatus}
                    onClick={() =>
                      window.open("https://viewer.trendads.ai/", "_blank")
                    }
                  >
                    Watch Ad
                  </button>
                </div>
              </div>
            </div> */}

            {/* dev: Total Commission Earned  */}
            <div className="row g-4 mb-4 mt-1">
              <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                <div className="dash-global-wrap">
                  <div className="rank-bonus mb-3 line-effect text-muted">
                    {t("Total Unilevel Commission Earned")} <br />
                    (USDC)
                  </div>
                  <div className="rank-bonus-num mb-2 pb-1 text-muted">
                    {t("Level")} 1 - 10
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <img src={busd} alt="" className="img-fluid" />
                    <span className="rank-bonus-num font-gilroy h3 mb-0">
                      {uniLevelEarningValue}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                <div className="dash-global-wrap">
                  <div className="rank-bonus mb-3 line-effect text-muted">
                    {t("Total Rank Bonus Commission")} <br />
                    (USDC)
                  </div>
                  <div className="rank-bonus-num mb-2 pb-1 text-muted">
                    {t("Rank Bonus")}
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <img src={busd} alt="" className="img-fluid" />

                    <span className="rank-bonus-num font-gilroy h3 mb-0">
                      {RBEarningBUSDValue}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                <div className="dash-global-wrap">
                  <div className="mb-3 line-effect text-muted">
                    {t("Total Rank Bonus Commission Claimed")} (USDC)
                  </div>
                  <div className="mb-2 pb-1 text-muted">{t("Rank Bonus")}</div>
                  <div className="d-flex align-items-center gap-2">
                    <img src={busd} alt="" className="img-fluid" />
                    <span className="font-gilroy h3 mb-0">
                      {RBClaimedEVCValue}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* {showPopup && adsData?.length > 0 && (
              <AdPopup ads={adsData} onClose={handleClosePopup} />
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RankBonus;
