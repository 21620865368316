import React from 'react'
import { ToastContainer } from 'react-toastify'
import "../../assets/scss/theme/dashboard/TrendProducts.scss"
import trendadlogo from "../../assets/img/dashboard/icons/trendads.png"
import trenddx from "../../assets/img/dashboard/icons/trenddxlogo.png"
import trendifyweblogo from "../../assets/img/dashboard/icons/trendifyweblogo.png"
import trendpad from "../../assets/img/dashboard/icons/trendpad.png"
import trendadsCover from "../../assets/img/dashboard/icons/TrendadCover.svg"
import trenddxCover from "../../assets/img/dashboard/icons/TrendDex.svg"
import trendifywebCover from "../../assets/img/dashboard/icons/TrendifyWeb.svg"
import trendpadCover from "../../assets/img/dashboard/icons/TrendPad.svg"
import trendifyTokenLogo from "../../assets/img/dashboard/icons/TrendifyTokenLogo.png"
import trendifyTokenCover from "../../assets/img/dashboard/icons/TrendifyTokenCover.svg"
import { redirect } from 'react-router-dom'
import {useTranslation} from "react-i18next"



function TrendProducts() {
const {t} = useTranslation();
  const redirectToWebsite = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };


  return (
    <div style={{ background: "#201f24", height: "100vh" }}>
      {/* dev: Token Details */}
      <ToastContainer />
      <div className="dashboard-wrap" style={{ backgroundColor: "#201F24" }}>
        <div className="dash-content-area-shree mt-1" style={{ backgroundColor: "#201f24" }}>
          <div className="row g-3">
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4 col-xxl-4">
              <div className="trendproduct-card">
                <div className="trendproduct-card-img-overlay">
                  <img src={trendifyweblogo} className="trendproduct-card-title" alt="trendifyweb" />
                </div>
                <img src={trendifywebCover} className="trendproduct-card-img-top" alt="trendifywebCover" />
                <div className="trendproduct-card-body">
                  <p className="trendproduct-card-text">{t("Get your Website Designed and Published using Al, with TrendifyWeb.")}</p>
                  <button onClick={() => redirectToWebsite("https://trendifyweb.ai/")} className="evc-bv-title btn btn-primary-bg-custom  " style={{ background: "linear-gradient(149.3deg, #02DB5B -29.94%, #4977C1 135.03%", color: "white" }}>{t("Explore Now")}</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4 col-xxl-4">
              <div className="trendproduct-card">
                <div className="trendproduct-card-img-overlay">
                  <img src={trendifyTokenLogo} className="trendproduct-card-title" alt="trendifyTokenLogo" />
                
                </div>
                <img src={trendifyTokenCover} className="trendproduct-card-img-top" alt="trendifyTokenCover" />
                <div className="trendproduct-card-body">
                  <p className="trendproduct-card-text">{t("Create & manage your own crypto tokens across multiple blockchain networks.")}</p>
                  <button 
                   onClick={() => redirectToWebsite("https://trendifytokens.io/")}
                   className="evc-bv-title btn btn-primary-bg-custom  " style={{ background: "linear-gradient(149.3deg, #02DB5B -29.94%, #4977C1 135.03%", color: "white" }}>{t("Explore Now")}</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4 col-xxl-4">
              <div className="trendproduct-card">
                <div className="trendproduct-card-img-overlay">
                  <img src={trendpad} className="trendproduct-card-title" alt="Trenddx" />
                </div>
                <img src={trendpadCover} style={{borderRadius:"2px"}} className="trendproduct-card-img-top" alt="..." />
                <div className="trendproduct-card-body">
                  <p className="trendproduct-card-text">{t("TrendPad: Empowering Innovations - Your Gateway to the Future of Crypto.")}</p>
                  <button 
                  // onClick={() => redirectToWebsite("https://trenddx.io/")}
                   className="evc-bv-title btn btn-primary-bg-custom  " style={{ background: "linear-gradient(149.3deg, #02DB5B -29.94%, #4977C1 135.03%", color: "white" }}>{t("Coming Soon")}</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4 col-xxl-4">
              <div className="trendproduct-card">
                <div className="trendproduct-card-img-overlay">
                  <img src={trenddx} className="trendproduct-card-title" alt="Trenddx" />
                </div>
                <img src={trenddxCover} className="trendproduct-card-img-top" alt="..." />
                <div className="trendproduct-card-body">
                  <p className="trendproduct-card-text">{t("Swap your any Coin with your favorite once now with Trenddx.")}</p>
                  <button onClick={() => redirectToWebsite("https://trenddx.io/")} className="evc-bv-title btn btn-primary-bg-custom  " style={{ background: "linear-gradient(149.3deg, #02DB5B -29.94%, #4977C1 135.03%", color: "white" }}>{t("Explore Now")}</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4 col-xxl-4">
              <div className="trendproduct-card">
                <div className="trendproduct-card-img-overlay">
                  <img src={trendadlogo} className="trendproduct-card-title" alt="Trendads" />
                </div>
                <img src={trendadsCover} className="trendproduct-card-img-top" alt="..." />
                <div className="trendproduct-card-body ">
                  <p className="trendproduct-card-text ">{t("Watch Ads and earn rewards or be a Advertisers only on Trendads.")}</p>

                  <button onClick={() => redirectToWebsite("https://trendads.ai/")} className="evc-bv-title btn btn-primary-bg-custom  " style={{ background: "linear-gradient(149.3deg, #02DB5B -29.94%, #4977C1 135.03%", color: "white" }}>{t("Explore Now")}</button>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TrendProducts