import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import ApexCharts from 'apexcharts';
import { URLDOMAIN } from "../../../ContractAction/ContractDependency";


const AreaChartGraph = ({ Data, setData, userAddress }) => {


  const [selection, setSelection] = useState('all');
  const [startDate, setstartDate] = useState("")
  const [endtDate, setendDate] = useState("")
  console.log("startDate", startDate)
  console.log("endtDate", endtDate)

  const [options, setOptions] = useState({
    colors: ['rgba(11, 134, 104,0.01)'],
    chart: {
      id: "tokenChart",
      background: 'rgba(0, 0, 0, 0)',
      toolbar: {
        show: false
      },
      zoom: {
        autoScaleYaxis: true
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 5
      }
    },
    grid: {
      show: true,
      borderColor: 'rgba(235, 237, 240, 0.03)',
      xaxis: {
        lines: {
          show: false
        }
      }
    },
    xaxis: {
      yAxisIndex: 1,
      type: 'datetime',
      labels: {
        show: true
      },
      axisBorder: {
        show: true,
        color: 'rgba(235, 237, 240, 0.03)'
      },
      tickPlacement: "on"
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return "$" + value.toFixed(0);
        }
      }
    },
    tooltip: {
      show: false
    },
    theme: {
      mode: "dark",
      monochrome: {
        enabled: false,
        color: '#fff',
        shadeTo: 'dark',
        shadeIntensity: 0
      },
    },
    legend: {
      show: false
    },
    stroke: {
      curve: 'smooth',
      width: 1, // Width of the border
      colors: ['#0B8668'] // Color of the border
    },
    dataLabels: {
      enabled: false
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: 'dark',
        shadeIntensity: 1,
        opacityFrom: 2,
        opacityTo: 3,
        stops: [20, 250]
      }
    }
  });
  const [series, setSeries] = useState([
    {
      name: 'Earnings',
      data: []
    }
  ]);

  useEffect(() => {
    setSeries([
      {
        name: 'Earnings',
        data: Data
      }
    ]);
  }, [Data]);



  const updateData = (timeline) => {

    setSelection(timeline);

    switch (timeline) {
      case 'one_day':
        fetch(`${URLDOMAIN}api/partneractivity/getusergraphtoken/${userAddress}?useraddress=${userAddress}`)
          .then((response) => response.json())
          .then((data) => {
            if (data.status == true) {
              setData(data?.GraphData);
              const dates = data?.GraphData.map((item) => new Date(item[0]).getTime());
              const firstDate = new Date(Math.min(...dates));
              const lastDate = new Date(Math.max(...dates));

              const options = { year: 'numeric', month: 'short', day: 'numeric' };
              const formattedfirstDate = new Date(firstDate).toLocaleDateString('en-US', options);
              const formattedlastDate = new Date(lastDate).toLocaleDateString('en-US', options);
              setstartDate(formattedfirstDate)
              setendDate(formattedlastDate)
              ApexCharts.exec('tokenChart', 'zoomX', firstDate.getTime(), lastDate.getTime());

            }
          })
          .catch((err) => {
            console.log('error', err);
          });
        break;
      case 'one_week':
        fetch(`${URLDOMAIN}api/partneractivity/getusergraphtokenweekly/${userAddress}?useraddress=${userAddress}`)
          .then((response) => response.json())
          .then((data) => {
            if (data.status == true) {
              setData(data?.GraphData);
              const dates = data?.GraphData.map((item) => new Date(item[0]).getTime());
              const firstDate = new Date(Math.min(...dates));
              const lastDate = new Date(Math.max(...dates));
              const options = { year: 'numeric', month: 'short', day: 'numeric' };
              const formattedfirstDate = new Date(firstDate).toLocaleDateString('en-US', options);
              const formattedlastDate = new Date(lastDate).toLocaleDateString('en-US', options);
              setstartDate(formattedfirstDate)
              setendDate(formattedlastDate)
              ApexCharts.exec('tokenChart', 'zoomX', firstDate.getTime(), lastDate.getTime());
            }
          })
          .catch((err) => {
            console.log('error', err);
          });
        break;
      case 'one_month':
        fetch(`${URLDOMAIN}api/partneractivity/getusergraphtokenmonthly/${userAddress}?useraddress=${userAddress}`)
          .then((response) => response.json())
          .then((data) => {
            if (data.status == true) {
              setData(data?.GraphData);
              const dates = data?.GraphData.map((item) => new Date(item[0]).getTime());
              const firstDate = new Date(Math.min(...dates));
              const lastDate = new Date(Math.max(...dates));
              const options = { year: 'numeric', month: 'short', day: 'numeric' };
              const formattedfirstDate = new Date(firstDate).toLocaleDateString('en-US', options);
              const formattedlastDate = new Date(lastDate).toLocaleDateString('en-US', options);
              setstartDate(formattedfirstDate)
              setendDate(formattedlastDate)
              ApexCharts.exec('tokenChart', 'zoomX', firstDate.getTime(), lastDate.getTime());
            }
          })
          .catch((err) => {
            console.log('error', err);
          });
        break;
      case 'six_month':
        fetch(`${URLDOMAIN}api/partneractivity/getusergraphtokensixmonths/${userAddress}?useraddress=${userAddress}`)
          .then((response) => response.json())
          .then((data) => {
            if (data.status == true) {
              setData(data?.GraphData);
              const dates = data?.GraphData.map((item) => new Date(item[0]).getTime());
              const firstDate = new Date(Math.min(...dates));
              const lastDate = new Date(Math.max(...dates));
              const options = { year: 'numeric', month: 'short', day: 'numeric' };
              const formattedfirstDate = new Date(firstDate).toLocaleDateString('en-US', options);
              const formattedlastDate = new Date(lastDate).toLocaleDateString('en-US', options);
              setstartDate(formattedfirstDate)
              setendDate(formattedlastDate)
              ApexCharts.exec('tokenChart', 'zoomX', firstDate.getTime(), lastDate.getTime());
            }
          })
          .catch((err) => {
            console.log('error', err);
          });
        break;
      case 'all':
        fetch(`${URLDOMAIN}api/partneractivity/getusergraphtokenoneyear/${userAddress}?useraddress=${userAddress}`)
          .then((response) => response.json())
          .then((data) => {
            if (data.status == true) {
              setData(data?.GraphData);
              const dates = data?.GraphData.map((item) => new Date(item[0]).getTime());
              const firstDate = new Date(Math.min(...dates));
              const lastDate = new Date(Math.max(...dates));

              const options = { year: 'numeric', month: 'short', day: 'numeric' };
              const formattedfirstDate = new Date(firstDate).toLocaleDateString('en-US', options);
              const formattedlastDate = new Date(lastDate).toLocaleDateString('en-US', options);
              setstartDate(formattedfirstDate)
              setendDate(formattedlastDate)
              ApexCharts.exec('tokenChart', 'zoomX', firstDate.getTime(), lastDate.getTime());
            }
          })
          .catch((err) => {
            console.log('error', err);
          });
        break;
      default:
    }
  }

  return (
    <>
      <div className="row">
        <div className="col-sm-6">
          <div className="d-flex align-items-center">
            <div className="chart-btn-group">
              <button onClick={() => updateData('one_day')} className={`chart-btn ${selection === 'one_day' ? 'active' : ''}`}> 24H </button>
              <button onClick={() => updateData('one_week')} className={`chart-btn ${selection === 'one_week' ? 'active' : ''}`}> 7D </button>
              <button onClick={() => updateData('one_month')} className={`chart-btn ${selection === 'one_month' ? 'active' : ''}`}> 1M </button>
              <button onClick={() => updateData('six_month')} className={`chart-btn ${selection === 'six_month' ? 'active' : ''}`}> 6M </button>
              <button onClick={() => updateData('all')} className={`chart-btn ${selection === 'all' ? 'active' : ''}`}> All </button>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="d-flex justify-content-end gap-3">
            <div className="d-flex align-items-center gap-2">
              <div className="icon-square icon-circle icon-16" style={{ backgroundColor: '#306FFF' }}></div>
              <span className="d-inline-block py-1 px-2 rounded bg-12 text-md" style={{ color: 'white' }}>{startDate}</span>
            </div>
            <div className="d-flex align-items-center gap-2">
              <div className="icon-square icon-circle icon-16" style={{ backgroundColor: '#30C9C9' }}></div>
              <span className="d-inline-block py-1 px-2 rounded bg-12 text-md" style={{ color: 'white' }}>{endtDate}</span>
            </div>
          </div>
        </div>
      </div>
      <div id="tokenChart">
        <ReactApexChart options={options} series={series} type="area" height={400} />
      </div>
    </>
  );
}

export default AreaChartGraph;