import React, { useEffect, useState } from 'react'
import { init, useConnectWallet } from '@web3-onboard/react'
import injectedModule from '@web3-onboard/injected-wallets'
import { ethers } from 'ethers'
import { useLocation,useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
const apiKey = '1730eff0-9d50-4382-a3fe-89f0d34a2070'

const injected = injectedModule()

const infuraKey = '5a15aa3454c24ab482eefb35dc2a1f57'
const rpcUrl = `https://mainnet.infura.io/v3/${infuraKey}`

// initialize Onboard
const onboard = init({
  apiKey,
  wallets: [injected],
  chains: [
    {
      id: '0x1',
      token: 'ETH',
      label: 'Ethereum Mainnet',
      rpcUrl
    },
    {
      id: '0x38',
      token: 'BNB',
      label: 'Binance Smart Chain Mainnet',
      rpcUrl: 'https://bsc-dataseed.binance.org/'
    },
    {
      id: 42161,
      token: 'ARB-ETH',
      label: 'Arbitrum One',
      rpcUrl: 'https://rpc.ankr.com/arbitrum'
    },
    {
      id: '0xa4ba',
      token: 'ARB',
      label: 'Arbitrum Nova',
      rpcUrl: 'https://nova.arbitrum.io/rpc'
    },
    {
      id: '0x2105',
      token: 'ETH',
      label: 'Base',
      rpcUrl: 'https://sepolia.base.org'
    },
    {
      id: '84532',
      token: 'ETH',
      label: 'sepolia Base',
      rpcUrl: 'https://sepolia.base.org'
    }
  ]
})

function ConnectButton() {

  const location = useLocation();

  const navigate = useNavigate()
  const isDashboardRoute = location.pathname.startsWith('/dashboard');

  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet()
  const [walletAddress, setWalletAddress] = useState(localStorage.getItem('connectedAccount') || '')
  const {t} = useTranslation();
  console.log("walletAddress",walletAddress)
  console.log("wallet",wallet)
  // create an ethers provider
  let ethersProvider = null
  if (wallet) {
    ethersProvider = new ethers.providers.Web3Provider(wallet.provider, 'any')
  }

  // useEffect(() => {
  //   const savedWalletAddress = localStorage.getItem('connectedAccount')
  //   if (savedWalletAddress && !wallet) {
  //     connect()
  //   }
  // }, [connect])

  useEffect(() => {
    if (wallet) {
      const address = wallet.accounts[0].address
      setWalletAddress(address)
      localStorage.setItem('connectedAccount', address)
      navigate("/dashboard/tt-avatars")
      // Listen for account changes
      wallet.provider.on('accountsChanged', (accounts) => {
        if (accounts.length > 0) {
          const newAddress = accounts[0]
          setWalletAddress(newAddress)
          localStorage.setItem('connectedAccount', newAddress)
        } else {
          setWalletAddress('')
          localStorage.removeItem('connectedAccount')
        }
      })

      // Clean up the event listener
      return () => {
        wallet.provider.removeListener('accountsChanged', () => {})
      }
    }
  }, [wallet])

 
  console.log("location", location.pathname);

  // Effect to update localStorage and state when walletAddress changes
  useEffect(() => {
    if (walletAddress) {
      localStorage.setItem('connectedAccount', walletAddress)
    } else {
      localStorage.removeItem('connectedAccount')
    }
  }, [walletAddress])

  const handleDisconnect = () => {
    disconnect(wallet)
    setWalletAddress('')
    localStorage.removeItem('connectedAccount')
    navigate("/")
  }

  const redirectToDashboard = () => {
    navigate("/dashboard/tt-avatars")
  }

  return (
    <>
      <div className="d-none d-xl-block">
        <button className="btn btn-primary-bg-custom" disabled={connecting} onClick={() => (walletAddress ? redirectToDashboard() : connect())}
          style={{ marginLeft: 20, borderWidth: "1px", borderRadius: "8px", borderColor: "#25a98e", color: 'white' }}>
          {walletAddress
            ? `${walletAddress.substring(0, 4)}...${walletAddress.substring(walletAddress.length - 4)}`
            : t("Connect Wallet")}
        </button>
      </div>

      {walletAddress && isDashboardRoute ?  (
        <button
          className="social-buttons nav-tata" style={{ display: "flex", gap: '0px 10px', width: "102px", height: "40px", marginRight: "3vw", border: "1px solid rgb(37, 169, 142)", borderRadius: "8px", background: "rgba(0,0,0,0.7)", fontSize: "16px", color: "white", flexDirection: 'row', alignItems: "center", justifyContent: "center" }}
          onClick={handleDisconnect}
        >
          {t("Log Out")}
        </button>
      ) : null }
    </>
  )
}

export default ConnectButton
