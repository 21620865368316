import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import TokenChart from "./Charts/TokenChart";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
//dev: import Icons
import { ReactComponent as Icon1 } from "../../assets/img/dashboard/icons/tokend-icon-1.svg";
import { ReactComponent as Icon2 } from "../../assets/img/dashboard/icons/tokend-icon-2.svg";
import { ReactComponent as Icon3 } from "../../assets/img/dashboard/icons/tokend-icon-3.svg";
// import { ReactComponent as Icon4 } from "../../assets/img/dashboard/icons/tokend-icon-4.svg";  //before
import { ReactComponent as Sidebar10 } from "../../assets/img/dashboard/icons/sidebar-10.svg";  //after
import { ReactComponent as Icon5 } from "../../assets/img/dashboard/icons/tokend-icon-5.svg";
import { ReactComponent as Icon6 } from "../../assets/img/dashboard/icons/tokend-icon-5.svg";
import Icon7 from "../../assets/img/dashboard/icons/tokend-icon-7.png";
import Icon8 from "../../assets/img/dashboard/icons/tokend-icon-8.png";
import { ReactComponent as IconEx } from "../../assets/img/dashboard/icons/tokend-icon-ex.svg";
import { ReactComponent as IconCopy } from "../../assets/img/dashboard/icons/tokend-icon-copy.svg";
import { getVestingBalanceEVC, getOwnerBalance, getEVCTotalSupply, getTotalEvcCirculatingSupply } from "../../ContractAction/EVCStakeContractAction";
import { getValueOutEvcToBusd } from "../../ContractAction/EVCRouterContractAction";
import { ContractAddressTRNDToken } from "../../ContractAction/ContractDependency";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Trendlogo from "../../assets/img/dashboard/icons/TrendLogo.png";
import { useTranslation } from "react-i18next";

// dev: Start TokenDetails Page
const TokenDetails = ({ title }) => {
  //dev: Get the address for Local storage
  const totalEVCSUpply = 1000000000;
  const [vestingBalance, setVestingBalance] = useState(0);
  const [ownerBalance, setOwnerBalance] = useState(0);
  // const [totalStaked, setTotalStaked] = useState(0);
  const [farmedValue, setFarmedValue] = useState(0);
  const [EVCTotalSupply, setEVCTotalSupply] = useState();
  const [EVCCirculatingSupply, setEVCCirculatingSupply] = useState();
  const {t} = useTranslation();
  const [EVCPrice, setEVCPrice] = useState();
  console.log("vestingBalance", vestingBalance)

  useEffect(() => {
    const getData = async () => {
      const vestingBalanceInfo = await getVestingBalanceEVC();
      const ownerBalanceINfo = await getOwnerBalance();
      const EVCTotalsupply = await getEVCTotalSupply();
      const EVCCirculatingsupply = await getTotalEvcCirculatingSupply();
      let evcAmount = 1
      const EVCPriceInBusd = await getValueOutEvcToBusd(evcAmount)
      // const stakedInfo = await getTotalStakedEVC();
      setVestingBalance(vestingBalanceInfo); //NOTE: Substract as per bucket value
      setOwnerBalance(ownerBalanceINfo);
      setEVCTotalSupply(EVCTotalsupply)
      setEVCCirculatingSupply(EVCCirculatingsupply)
      setEVCPrice(EVCPriceInBusd)
      // setTotalStaked(stakedInfo);
    }
    getData();
  }, []);

  const handleCopy = (text) => {
    navigator.clipboard.writeText(`${text}`);
    toast.success("Copied!", { autoClose: 1000 });
  };

  useEffect(() => {
    document.title = title ? title : "TT Avatars | Token Details";
    document.querySelector(".page-title").innerText = "Token Details";
  }, []);

  return (
    <div style={{ background: "#201f24", height: "100vh" }}>
      {/* dev: Token Details */}
      <ToastContainer />
      <div className="dashboard-wrap" style={{ backgroundColor: "#201F24", height: "100vh" }}>
        <div className="dash-content-area-shree mt-1" style={{ backgroundColor: "#201f24", height: "100vh" }}>
          <TokenChart />
          {/* <div className="h1 font-gilroy fw-semibold mt-5 mb-4">General Stats</div> */}
          <div className="h4 font-gilroy fw-semibold ">
           {t("General Stats")}
          </div>
          <div className="" style={{ backgroundColor: "transparent" }}>
            <div className="row g-2" style={{ backgroundColor: "#201F24" }}>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4" >
                <div className="dash-token-card" >
                  <div className="icon-square icon-md">
                    <Icon1 />
                  </div>
                  <div className="card-title">
                    <div className="pre-title">{t("Symbol")}</div>
                    <div className="main-title">{t("$ TRND Token")}</div>
                  </div>
                  <div className="card-tooltip">
                    <OverlayTrigger
                      placement={`top`}
                      overlay={
                        <Tooltip id={`tooltip-top`} style={{ backgroundColor: "#9F9F9F" }}>
                          {t("Trend Token Symbol")}
                        </Tooltip>
                      }
                    >
                      <div>
                        <IconEx />
                      </div>
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                <div className="dash-token-card" >
                  <div className="icon-square icon-md">
                    <Icon2 />
                  </div>
                  <div className="card-title">
                    <div className="pre-title">{t("Total Supply")}</div>
                    <div className="main-title">{Number(EVCTotalSupply).toFixed(4)}</div>
                    {/* <div className="main-title">21,000,000</div> */}
                  </div>
                  <div className="card-tooltip">
                    <OverlayTrigger
                      placement={`top`}
                      overlay={
                        <Tooltip id={`tooltip-top`} style={{ backgroundColor: "#9F9F9F" }}>
                          {t("Total TRND Supply.")}
                        </Tooltip>
                      }
                    >
                      <div>
                        <IconEx />
                      </div>
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                <div className="dash-token-card" >
                  <div className="icon-square icon-md">
                    <Icon3 />
                  </div>
                  <div className="card-title">
                    <div className="pre-title">{t("Circulating Supply")}</div>
                    <div className="main-title">{Number(EVCCirculatingSupply).toFixed(4)}</div>
                    {/* <div className="main-title">21,000,000</div> */}
                  </div>
                  <div className="card-tooltip">
                    <OverlayTrigger
                      placement={`top`}
                      overlay={
                        <Tooltip id={`tooltip-top`} style={{ backgroundColor: "#9F9F9F" }}>
                          {t("Total TRND in Circulation.")}
                        </Tooltip>
                      }
                    >
                      <div>
                        <IconEx />
                      </div>
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                <div className="dash-token-card" >
                  <div className="icon-square icon-md">
                    <Sidebar10 />
                  </div>
                  <div className="card-title">
                    <div className="pre-title">{t("TRND Price")}</div>
                    <div className="main-title">${Number(EVCPrice).toFixed(6)}</div>
                    {/* <div className="main-title">$0.21</div> */}
                  </div>
                  <div className="card-tooltip">
                    <OverlayTrigger
                      placement={`top`}
                      overlay={
                        <Tooltip id={`tooltip-top`} style={{ backgroundColor: "#9F9F9F" }}>
                          {t("Current Price of TRND in BUSD")}
                        </Tooltip>
                      }
                    >
                      <div>
                        <IconEx />
                      </div>
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row g-2 mt-1 pt-2" >
            <div className="col-lg-8" >
              <div className="dash-global-wrap h-100">
                <div className="h4 mb-4 font-gilroy fw-semibold">
                 {t("TRND Token Contract Address(Base)")}
                </div>
                {/* <div className="h1 font-gilroy fw-semibold mb-4">Buy TT Tokens</div> */}
                <div className="d-flex gap-3 align-items-center flex-wrap" >
                  {/* NOTE: Leave this Pancake and KuCoin as Commented as DEX may be available in the future */}
                  <div className="d-flex align-items-center g-3 py-2 ps-4 pe-3 bg-12 rounded-2 mw-100" style={{ backgroundColor: "#272729" }}>
                    <div className="overflow-hidden text-nowrap fw-bold  col-11 text-lg mt-1 opacity-75" >
                      CA: 0x0000000000000000000000000000000000000000
                      {/* NOTE: update the address with latest one */}
                    </div>
                    <div className="icon-square icon-sm ms-auto cursor-pointer mb-1">
                      <OverlayTrigger
                        placement={`top`}
                        overlay={<Tooltip id={`tooltip-top`}>{t("Copy")}</Tooltip>}
                      >
                        <div>
                          <IconCopy onClick={() => handleCopy(`${ContractAddressTRNDToken}`)} />
                        </div>
                      </OverlayTrigger>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4" >
              <div className="dash-global-wrap">
                <div className="h3 font-gilroy fw-semibold mb-4">{t("Taxation")}</div>
                <div className="d-grid gap-2">
                  <button className="" style={{ backgroundColor: "#519c4b", color: "white", borderRadius: "4px", height: "48px" }}>3% Buy</button>
                  <button className="" style={{ backgroundColor: "#a33b3b", color: "white", borderRadius: "4px", height: "48px" }} >3% Sell</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TokenDetails;