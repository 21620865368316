import React, { useState, useEffect } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { ReactComponent as Sidebar1 } from "../../../assets/img/dashboard/icons/sidebar-1.svg";
import { ReactComponent as Sidebar2 } from "../../../assets/img/dashboard/icons/sidebar-2.svg";
import { ReactComponent as Sidebar3 } from "../../../assets/img/dashboard/icons/sidebar-3.svg";
import { ReactComponent as Sidebar4 } from "../../../assets/img/dashboard/icons/sidebar-4.svg";
import { ReactComponent as Sidebar5 } from "../../../assets/img/dashboard/icons/sidebar-5.svg";
import { ReactComponent as Sidebar6 } from "../../../assets/img/dashboard/icons/sidebar-6.svg";
import { ReactComponent as Sidebar7 } from "../../../assets/img/dashboard/icons/sidebar-7.svg";
import { ReactComponent as Sidebar8 } from "../../../assets/img/dashboard/icons/sidebar-8.svg";
import { ReactComponent as Sidebar9 } from "../../../assets/img/dashboard/icons/sidebar-9.svg";
import { ReactComponent as Sidebar10 } from "../../../assets/img/dashboard/icons/sidebar-10.svg";
import { ReactComponent as Sidebar11 } from "../../../assets/img/dashboard/icons/sidebar-11.svg";
import { ReactComponent as Sidebar12 } from "../../../assets/img/dashboard/icons/sidebar-12.svg";
import { ReactComponent as Sidebar13 } from "../../../assets/img/icons/reflinkicon.svg";
import { ReactComponent as Sidebar14 } from "../../../assets/img/icons/avatminericon.svg";
import { NavLink, Navigate } from "react-router-dom";
import { ReactComponent as EvcIcon } from "../../../assets/img/dashboard/icons/evc-icon.svg";
import { useTranslation } from "react-i18next";
import {
  FaUserCircle,
  FaTwitter,
  FaPaperPlane,
  FaDiscord,
  FaFacebookF,
  FaTiktok,
  FaInstagram,
} from "react-icons/fa";

import NavDropdown from "react-bootstrap/NavDropdown";

import twiterbw from "../../../assets/img/icons/twitter.png";
import instabw from "../../../assets/img/icons/instagram.png";
import gitbw from "../../../assets/img/icons/github.png";
import tiktokbw from "../../../assets/img/icons/tiktok.png";
import telebw from "../../../assets/img/icons/telegram.png";
import useMediaQuery from "@mui/material/useMediaQuery";

// import { getBalanceOfPreSale } from "../../../ContractAction/PreSaleContractAction"; //cmnt
//dev: Start Sidebar Component
const Sidebar = ({ sideToggle, setSideToggle, presaleBalanceOf }) => {
  // const refresh = () => window.location.reload(true)
  const [preevc, setPreev] = useState();
  const { t, i18n } = useTranslation();
  const isMobile = useMediaQuery("(max-width:991px)");

  const languages = [
    { name: "English", code: "en" },
    { name: "German", code: "de" },
    { name: "Russian", code: "ru" },
    { name: "Vietnamese", code: "vi" },
    { name: "Chinese", code: "zh" },
    { name: "Spanish", code: "es" },
    { name: "Korean", code: "ko" },
    { name: "Japanese", code: "ja" },
    { name: "Thai", code: "th" },
    { name: "French", code: "fr" },
  ];
  const handleSelect = (eventKey) => {
    const selectedLang = languages.find((lang) => lang.code === eventKey);
    setSelectedLanguage(selectedLang);
    changeLanguage(eventKey);
  };
  const [selectedLanguage, setSelectedLanguage] = useState(() => {
    const savedLangCode = localStorage.getItem("i18nextLng");
    return savedLangCode
      ? languages.find((lang) => lang.code === savedLangCode)
      : languages[0];
  });
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  useEffect(() => {
    const getData = async () => {
      // let Preevc = await getBalanceOfPreSale(); //cmnt
      // setPreev(Preevc); //cmnt
    };
    getData();
  }, []);
  const newAddress = window.localStorage.getItem("connectedAccount");

  if (newAddress === null) {
    return <Navigate to="/" />;
  }

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setSideToggle(false);
      }}
    >
      <div
        className={`d-flex flex-column cbd-sidebar${sideToggle ? " show" : ""}`}
        style={{ backgroundColor: "#2c2c31" }}
      >
        <div className="d-flex d-sm-none gap-3 flex-column mb-2 p-3 border-bottom">
          <div className="d-flex align-items-center gap-3 d-sm-none">
            <div className="cursor-pointer icon-square icon-circle icon-user p-2">
              <FaUserCircle />
            </div>
            <div>
              <div className="text-uppercase text-sm opacity-75">HOLDER</div>
              <div>18,751</div>
            </div>
          </div>
          <div className="d-flex align-items-center gap-3 d-sm-none">
            <div className="cursor-pointer icon-square icon-circle bg-gradient">
              <EvcIcon />
            </div>
            <div>
              <div className="text-uppercase text-sm opacity-75">
                TRND TOKEN
              </div>
              <div>8,967,00</div>
            </div>
          </div>
        </div>

        <ul className="sidebar-list">
          {isMobile && (
            <div style={{padding:"10px 20px"}}>
              <NavDropdown
                title={selectedLanguage.name}
                id="navbarScrollingDropdown"
                onSelect={handleSelect}
                style={{ fontSize: "25px" }}
              >
                {languages.map((lang) => (
                  <NavDropdown.Item key={lang.code} eventKey={lang.code}>
                    {lang.name}
                  </NavDropdown.Item>
                ))}
              </NavDropdown>
            </div> 
          )}
          <li
            onClick={() => {
              setSideToggle(false);
            }}
          >
            <NavLink
              to="/dashboard/main"
              className={({ isActive }) =>
                isActive ? "active-dash" : undefined
              }
            >
              <div className="icon">
                <Sidebar1 />
              </div>
              <span
                className="sidebar-span"
                style={{ fontSize: "14px", color: "#9B9797" }}
              >
                {t("Dashboard")}
              </span>
            </NavLink>
          </li>
          <li
            onClick={() => {
              setSideToggle(false);
            }}
          >
            <NavLink
              to="/dashboard/tt-avatars"
              className={({ isActive }) =>
                isActive ? "active-dash" : undefined
              }
            >
              <div className="icon">
                <Sidebar2 />
              </div>
              <span
                className="sidebar-span"
                style={{ fontSize: "14px", color: "#9B9797" }}
              >
                {t("Buy TT Avatars")}
              </span>
            </NavLink>
          </li>
          <li
            onClick={() => {
              setSideToggle(false);
            }}
          >
            <NavLink
              to="/dashboard/myavatars"
              className={({ isActive }) =>
                isActive ? "active-dash" : undefined
              }
            >
              <div className="icon">
                <Sidebar3 />
              </div>
              <span
                className="sidebar-span"
                style={{ fontSize: "14px", color: "#9B9797" }}
              >
                {" "}
                {t("Avatars")}
              </span>
            </NavLink>
          </li>

          <li
            onClick={() => {
              setSideToggle(false);
            }}
          >
            <NavLink
              to="/dashboard/avatar-mining-reward"
              className={({ isActive }) =>
                isActive ? "active-dash" : undefined
              }
            >
              <div className="icon">
                <Sidebar13 />
              </div>
              <span
                className="sidebar-span"
                style={{ fontSize: "14px", color: "#9B9797" }}
              >
                {t("Avatars Minting Reward")}
              </span>
            </NavLink>
          </li>
          <li
            onClick={() => {
              setSideToggle(false);
            }}
          >
            <NavLink
              to="/dashboard/swap"
              className={({ isActive }) =>
                isActive ? "active-dash" : undefined
              }
            >
              <div className="icon">
                <Sidebar4 />
              </div>
              <span
                className="sidebar-span"
                style={{ fontSize: "14px", color: "#9B9797" }}
              >
                {t("Swap")}
              </span>
            </NavLink>
          </li>
          <li
            onClick={() => {
              setSideToggle(false);
            }}
          >
            <NavLink
              to="/dashboard/rank-bonus"
              className={({ isActive }) =>
                isActive ? "active-dash" : undefined
              }
            >
              <div className="icon">
                <Sidebar5 />{" "}
              </div>
              <span
                className="sidebar-span"
                style={{ fontSize: "14px", color: "#9B9797" }}
              >
                {t("Rank Bonus")}
              </span>
            </NavLink>
          </li>
          <li
            onClick={() => {
              setSideToggle(false);
            }}
          >
            <NavLink
              to="/dashboard/team-statistics"
              className={({ isActive }) =>
                isActive ? "active-dash" : undefined
              }
            >
              <div className="icon">
                <Sidebar6 />{" "}
              </div>
              <span
                className="sidebar-span"
                style={{ fontSize: "14px", color: "#9B9797" }}
              >
                {t("Team Statistics")}
              </span>
            </NavLink>
          </li>
          {/* <li
            onClick={() => {
              setSideToggle(false);
            }}
          >
            <NavLink
              to="/dashboard/staking"
              className={({ isActive }) => (isActive ? "active-dash" : undefined)}
            >
              <div className="icon">
                <Sidebar4 />
              </div>
              <span>Staking</span>
            </NavLink>
          </li>*/}

          <li
            onClick={() => {
              setSideToggle(false);
            }}
          >
            <NavLink
              to="/dashboard/token-details"
              className={({ isActive }) =>
                isActive ? "active-dash" : undefined
              }
            >
              <div className="icon">
                <Sidebar7 />
              </div>
              <span
                className="sidebar-span"
                style={{ fontSize: "14px", color: "#9B9797" }}
              >
                {t("Token Details")}
              </span>
            </NavLink>
          </li>
          {/* <li
            onClick={() => {
              setSideToggle(false);
            }}
          >
            <NavLink
              to="/dashboard/buy-burn"
              className={({ isActive }) => (isActive ? "active-dash" : undefined)}
            >
              <div className="icon">
                <Sidebar8 />
              </div>
              <span className="sidebar-span" style={{fontSize:"14px", color:"#9B9797"}}>Buy & Burn</span>
            </NavLink>
          </li> */}
          <li
            onClick={() => {
              setSideToggle(false);
            }}
          >
            <NavLink
              to="/dashboard/tred-products"
              className={({ isActive }) =>
                isActive ? "active-dash" : undefined
              }
            >
              <div className="icon">
                <Sidebar8 />
              </div>
              <span
                className="sidebar-span"
                style={{ fontSize: "14px", color: "#9B9797" }}
              >
                {t("Trend Products")}
              </span>
            </NavLink>
          </li>
          <li
            onClick={() => {
              setSideToggle(false);
            }}
          >
            <NavLink
              to="/dashboard/referralLink"
              className={({ isActive }) =>
                isActive ? "active-dash" : undefined
              }
            >
              <div className="icon">
                <Sidebar14 />
              </div>
              <span
                className="sidebar-span"
                style={{ fontSize: "14px", color: "#9B9797" }}
              >
                {t("Referral Link")}
              </span>
            </NavLink>
          </li>

          {preevc > 0 && (
            <li
              onClick={() => {
                setSideToggle(false);
              }}
            >
              <NavLink
                to="/dashboard/Presale"
                className={({ isActive }) =>
                  isActive ? "active-dash" : undefined
                }
              >
                <div className="icon">
                  <Sidebar11 />
                </div>
                <span
                  className="li-my-1"
                  style={{ fontSize: "14px", color: "#9B9797" }}
                >
                  Presale
                </span>
              </NavLink>
            </li>
          )}
          {preevc > 0 && (
            <li
              onClick={() => {
                setSideToggle(false);
              }}
            >
              <NavLink
                to="/dashboard/Redeem"
                className={({ isActive }) =>
                  isActive ? "active-dash" : undefined
                }
              >
                <div className="icon">
                  <Sidebar10 />
                </div>
                <span
                  className="sidebar-span"
                  style={{ fontSize: "14px", color: "#9B9797" }}
                >
                  Redeem
                </span>
              </NavLink>
            </li>
          )}
          {preevc > 0 && (
            <li
              onClick={() => {
                setSideToggle(false);
              }}
            >
              <NavLink
                to="/dashboard/vesting"
                className={({ isActive }) =>
                  isActive ? "active-dash" : undefined
                }
              >
                <div className="icon">
                  <Sidebar9 />
                </div>
                <span
                  className="sidebar-span"
                  style={{ fontSize: "14px", color: "#9B9797" }}
                >
                  Vesting
                </span>
              </NavLink>
            </li>
          )}
        </ul>
        <div className="m-3">
          <div className="mb-2" style={{ fontSize: "10px" }}>
            FOLLOW US:
          </div>
          <ul className="d-flex list-unstyled  ">
            <a
              href="https://twitter.com/TTAvatars"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                margin: "0 10px",
                textDecoration: "none",
                color: "inherit",
              }}
            >
              <img src={twiterbw} className="me-4" />
            </a>
            <a
              href="https://t.me/trendavatars"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                margin: "0 10px",
                textDecoration: "none",
                color: "inherit",
              }}
            >
              <img src={telebw} className="me-4" />
            </a>
            <a
              href="https://t.me/+Ssi1uUZQz6sxNGRl"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                margin: "0 10px",
                textDecoration: "none",
                color: "inherit",
              }}
            >
              <img src={telebw} className="me-4" />
            </a>
          </ul>
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default Sidebar;
