
import React, { useState, useEffect } from "react";
import BasicTable from "./PartnerActivityTable";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { getCurrentUserTeamSaleAndPartners, getTeamStatisticOfAddressResponse } from "../../../ContractAction/EVCNFTContractAction";
import { URLDOMAIN } from "../../../ContractAction/ContractDependency";
import AreaChartGraph from "./AreaChartGraph";
import ApexCharts from 'apexcharts';
import { useTranslation } from "react-i18next";
import {getNetworkExplorerUrl} from "../../../ContractAction/BUSDContractAction";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


//dev: Second Table
function createData1(
  wallet,
  rank,
  TotalPartner,
  ownNft,
  totalWeeklyTurnover,
  TotalNftTeamTurnOver
) {
  return {
    wallet,
    rank,
    TotalPartner,
    ownNft,
    totalWeeklyTurnover,
    TotalNftTeamTurnOver,
  };
}


function TeamStatistics({ title }) {

  const userAddress = localStorage.getItem("connectedAccount")
  // const userAddress = "0x6fa247a35348e8df42ffeed1699de0f8c1cbebbc"

  const [value, setValue] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [GraphData, setGraphData] = useState()
  const [data, setData] = useState([]);
  const [teamDetails, setTeamDetails] = useState()
  const [startDate, setstartDate] = useState("")
  const [endtDate, setendDate] = useState("")
  console.log("teamDetails", teamDetails)
  console.log("endtDate", endtDate)
  const { t } = useTranslation();

  console.log({ data })
  useEffect(() => {
    const fetchData = async () => {

      const teamStatisticData = await getTeamStatisticOfAddressResponse(userAddress);
      setData(teamStatisticData);
      console.log("teamStatisticData", teamStatisticData)

    };
    fetchData();
  }, [userAddress]);
  useEffect(() => {
    const fetchUserTeamsTotalData = async () => {
      const TeamDetails = await getCurrentUserTeamSaleAndPartners();
      // const data = await TeamDetails.json();
      console.log("fetchUserTeamsTotalData", TeamDetails)
      setTeamDetails(TeamDetails)

    };
    fetchUserTeamsTotalData();
  }, []);

  useEffect(() => {
    fetch(`${URLDOMAIN}api/partneractivity/getusergraphtokenoneyear/${userAddress}?useraddress=${userAddress}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.status == true) {
          setGraphData(data?.GraphData);
          const dates = data?.GraphData.map((item) => new Date(item[0]).getTime());
          const firstDate = new Date(Math.min(...dates));
          const lastDate = new Date(Math.max(...dates));
          const options = { year: 'numeric', month: 'short', day: 'numeric' };
          const formattedfirstDate = new Date(firstDate).toLocaleDateString('en-US', options);
          const formattedlastDate = new Date(lastDate).toLocaleDateString('en-US', options);
          setstartDate(formattedfirstDate)
          setendDate(formattedlastDate)
          ApexCharts.exec('tokenChart', 'zoomX', firstDate.getTime(), lastDate.getTime());
        }
      })
      .catch((err) => {
        console.log('error', err);
      });
  }, [])

  useEffect(() => {
    document.title = title ? title : "TRND Avatars | Team Statistics";
    document.querySelector(".page-title").innerText = "Team Statistics";
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div className="dashboard-wrap " style={{ background: "#201f24" }}>
      <div className="dash-content-area " style={{ borderRadius: "0px", background: "#201f24" }}>
        <div className="d-flex justify-content-between" >
          <h6 className="team-statics-text">{t("Total Team turnover")} - {teamDetails?.teamSale} USDC</h6>
          <h6 className="team-statics-text">{t("Total Team Member")} -  {teamDetails?.totalPartners}</h6>
        </div>
        {/* End First table */}
        <Card sx={{ background: '#0b2224', border: "0px solid", borderRadius: "8px" }}>

          <CardContent sx={{ color: "white" }}>
            <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
              <span className="team-statics-tet" style={{ color: "blue" }}>• </span>
              {t("Team Turnover")}
            </Typography>
          </CardContent>
          <Box >

            {/*dev: Render the Linechart component (NFT Turnover Graph)  */}
            <div
              id="chart"
              style={{ padding: "0px", margin: "0px" }}
            >
              <AreaChartGraph
                Data={GraphData}
                setData={setGraphData}
                userAddress={userAddress}
                startDate={startDate}
                setstartDate={setstartDate}
                endtDate={endtDate}
                setendDate={setendDate}
              />
            </div>
          </Box>
        </Card>

        <BasicTable
          userAddress={userAddress}
          data={data}
          setData={setData}
        />

      </div>
    </div>
  );
}

export default TeamStatistics;