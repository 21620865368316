import React, { useState, useEffect } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link } from "react-router-dom";
import { AiOutlineCopy } from "react-icons/ai";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getMyReferrer } from "../../ContractAction/EVCNFTContractAction";
import {
  APIUSEREFERRAL,
  APIDASHBOARDREF,
} from "../../ContractAction/ContractDependency";
import { useTranslation } from "react-i18next";

//dev: Postthe  data for API
const postData = () => {
  const affiliateaddress = window.localStorage.getItem("connectedAccount");
  const sponsoraddress = window.localStorage.getItem("sponsorAddress");
  console.log("sponsoraddress: ", sponsoraddress);
  const data = {
    affiliateaddress: affiliateaddress,
    sponsoraddress: sponsoraddress,
  };

  //dev: Fetch the API Data
  fetch(`${APIUSEREFERRAL}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((result) => {
      console.log("result....", result);
      return result.json();
    })
    .catch((err) => console.log(err));
};

const ReferralLink = ({ title }) => {
  const [myreferrer, setMyReferrer] = useState();
  useEffect(() => {
    const getData = async () => {
      let referrerInfo = await getMyReferrer();
      setMyReferrer(referrerInfo);
    };
    getData();
  }, []);
  console.log("myreferrer", myreferrer);

  const text = `${APIDASHBOARDREF}`;

  const affiliateaddress = window.localStorage.getItem("connectedAccount");
  const sponsoraddress = window.localStorage.getItem("sponsorAddress");

  postData();

  //dev: Copy text
  const handleCopy = (text) => {
    navigator.clipboard.writeText(`${text}${affiliateaddress}`);
    toast.success("Copied!", { autoClose: 1000 });
  };

  useEffect(() => {
    document.title = title ? title : "TT Avatars | Referral Link";
    document.querySelector(".page-title").innerText = "Referral Link";
  }, []);

  console.log("add", affiliateaddress);
  const { t } = useTranslation();
  return (
    <div
      className="dashboard-wrap"
      style={{ width: "100%", height: "100vh", background: "#201f24" }}
    >
      {/* <Breadcrumb>
        <li className="breadcrumb-item">
          <Link to="/">HOME</Link>
        </li>
        <Breadcrumb.Item active>Referral Link</Breadcrumb.Item>
      </Breadcrumb> */}

      <div className="dash-content-area-shree mt-1">
        {/* <div className="h1 font-gilroy fw-semibold mt-3 mb-4 pb-3"></div> */}
        <div class="d-flex justify-content-center   mt-3 flex-wrap">
          <div
            className="dash-global-wrap"
            style={{
              borderRadius: "12px",
              border: "1px solid gray",
              position: "absolute",
              top: "15%",
              // left: "30%",
              // height: "250px",
              width: "100%",
              maxWidth: "fit-content",
            }}
          >
            {/*dev:Show the Sponsor Address */}
            <p class="evc-title" style={{ fontSize: "18px", width: "auto" }}>
              {t("Sponsor Address")} :{" "}
            </p>
            <div>
              <Link
                class="stake-txt"
                style={{ width: "auto", color: "#1aba7e" }}
              >
                {myreferrer != "0x0000000000000000000000000000000000000000"
                  ? myreferrer
                  : sponsoraddress}
              </Link>
            </div>

            {/*dev:Show the  Reference Address */}
            <div className="mt-3">
              <p
                class="evc-title"
                style={{ fontSize: "18px", width: "auto", color: "" }}
              >
                {" "}
                {t("Referral Address")}:{" "}
              </p>
              <div
                style={{
                  border: "1px solid green",
                  borderRadius: "2px",
                  padding: "5px",
                }}
              >
                <Link class="stake-txt" style={{ color: "#1aba7e" }}>
                  {APIDASHBOARDREF}
                  {affiliateaddress}
                </Link>
              </div>
              <div
                style={{ marginTop: "10px" }}
                onClick={() => handleCopy(text)}
              >
                {/* <span style={{ cursor: "pointer", fontSize: "15px" }}>
                  Copy Link
                </span> */}
                <button
                  className="btn btn-primary-bg-custom  "
                  style={{
                    cursor: "pointer",
                    background:
                      "linear-gradient(149.3deg, #02DB5B -29.94%, #4977C1 135.03%",
                    color: "white",
                  }}
                >
                  {" "}
                  {t("Copy Link")}
                </button>

                {/* <AiOutlineCopy style={{ cursor: "pointer" }} /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferralLink;
